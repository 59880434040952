header {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 100px;
	background : {
		image: url('./images/header/head-ampmat.png');
		position: center top;
		repeat: no-repeat;
		color: #393835;
	}

	@include breakpoint(tabletwide) {
		
	}

	@include breakpoint(mobilewide) {
		display: block;
		height: auto;
	}

	h1 {
		margin: 0;
		font-weight: normal;
		z-index: 2;
		box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.4);

		a {
			text-decoration: none;
			background-color: #070707;
			width: 200px;
			height: 140px;
			margin: 0 auto;
			padding: 0;
			display: flex;
			flex: 1;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			svg {
				width: 80%;
				height: 80%;
				margin: 20px auto 0;
			}
			@include breakpoint(mobilewide) {
				width: 100%;

				svg {
					margin: 20px auto;
				}
			}
		}
	} //h1

	nav {
		margin: 0;
		padding: 0;
		//box-shadow: inset 0px -10px 10px -10px rgba(0,0,0,0.5);
		
		ul {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			list-style: none;
			margin: 0 auto;
			padding: 0;
			width: 886px;

			@include breakpoint(tabletwide) {
				width: 100%;
			}

			@include breakpoint(mobilewide) {
				flex-direction: column;
				align-content: center;
			}

			> li {
				padding: 0;


				a {
					display: block;
					text-decoration: none;
					color: #FFF;
					text-transform: lowercase;
					font-weight: 300;
					font-size: 1rem;
					line-height: normal;
					padding: 2.4rem 1.4rem;
					transition: background-color 400ms ease-out;
					text-shadow: 0px 1px 0px $dfxdark;

					&:hover {
						text-decoration: none;
						background-color: rgba($dfxdark, 0.6);
						color: #FFF;
					}

				}
				&.current_page_item, &.current-menu-item {
					a {
						background-color: rgba($dfxdark, 0.2);
					}
				}

				@include breakpoint(mobilewide) {
					width: 100%;

					a {
						padding: 1.4rem 1.4rem;
					}
				}
			} // > li

			@include breakpoint(tabletwide) {
				// padding: 0;
				// display: block;
				// width: 100%;
			}
		} // ul
	} // nav
} // header