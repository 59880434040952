
// Media Queries
@mixin breakpoint($point) {
	@if $point == desktop {
		@media (max-width: 1366px) { @content ; }
	} @else if $point == laptop {
		@media (max-width: 1280px) { @content ; }
	} @else if $point == tabletwide {
		@media (max-width: 1024px) { @content ; }
	} @else if $point == tablet-tall {
		@media (max-width: 768px) { @content ; }
	} @else if $point == mobilewide {
		@media (max-width: 640px) { @content ; }
	} @else if $point == mobile-tall {
		@media (max-width: 375px) { @content ; }
	}
}