
.woocommerce {
	
	ul.products {
		li.product {
			.price {
				color: #EEE;
			}
		}
	}
	
	// BUTTONS
	#respond input#submit,
	a.button,
	button.button,
	input.button,
	div.product form.cart .button {
		background-color: #77A464;
		color: #FFF;
	}

	a.button {
		&.checkout {
			background-color: #FF8700;
		}
	}

	// INPUTS
	input, textarea {
		color: #333;
	}


	.woocommerce-ordering {
		display: none;
	}

	div.product {
		.product_title {
			font-weight: 300;
			font-size: 1.6em;
			color: #FFF;
		}
		.woocommerce-tabs {
			ul.tabs {
				li {
					border: 1px solid #222;
					background-color: #000;
					
					a {
						color: #9D9D9D;
						font-weight: 300;
					} // a
					&::before, &::after {
						box-shadow: none;
						border: none;
					}

					&.active {
						border: 1px solid #222;
						background-color: #000;
						a {
							color: #9D9D9D;
							font-weight: 300;
						}
						&::before, &::after {
							box-shadow: none;
							border: none;
						}
					}
				} // li
				&::before {
					border-bottom: 1px solid #000000;
				}
			} // ul.tabs
		} // .woocommerce-tabs
	} // div.product

	.widget_shopping_cart .total,
	.woocommerce.widget_shopping_cart .total {
		border-top: 1px solid #2b292b;
		color: #FFF;
	}
} // .woocommerce


.woocommerce-message,
.woocommerce-info {
	background-color: #000;
	color: #FFF;
}