html, body {height: 100%;}

html {
	position: relative;
	font-size: 100%;
} // html

body {
	position: relative;
	font-family: 'Open Sans';
	font-size: 16px;
	line-height: 24px;
	background: {
		color: $dfxdark;
	}
	background-attachment: fixed;
	color: #444;

} // body

