main {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	//width: 1136px;
	width: 100%;
	margin: 0 auto;


	article.page {
		display: flex;
		flex-direction: row;
		margin: 2rem 0;
		width: 1080px;
		border-radius: .5rem;
		//box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.2);

		@include breakpoint(tabletwide) {
			width: 100%;
		}

		> section {
			width: 70%;
			box-sizing: border-box;
			padding: 2rem 1rem;
			color: #BEBEBE;
			font-size: 0.9rem;
			//background-color: rgba(255,255,255,0.95);

			> h1 {
				font-size: 2em;
				font-weight: 300;
				margin-top: 0;
				text-transform: uppercase;
				color: #FFF;
				display: block;
				text-shadow: 0px 2px 2px rgba(0,0,0,0.7);
			}

			h2 {
				font-size: 1.8em;
				font-weight: 300;
				text-transform: uppercase;
				color: #DDD;
			}

			h3 {font-weight: 400;}

			.meta {font-size: 0.8rem;}

			img {
				max-width: 100%;
				height: auto;
				&.alignleft {float: left; padding: 0 14px 0 0;}
				&.alignright {float: right; padding: 0 0 0 14px;}
				&.aligncenter {float: none; margin: 0 auto; display: block;}
			}
			iframe {
				max-width: 100%;
				// height: auto;
			}
			//WORDPRESS JUNK
			.wp-caption {
				background-color: #F3F3F3; padding: 8px; margin: 10px; max-width: 91%; height: auto;
				&-text {margin: 0; font-size: 0.8em;  padding: 8px 0 0 0; text-align: center;}
				img {float: none; margin: 0 auto; display: block; max-width: 100%; height: auto;}
				&.alignleft {float: left;}
				&.alignright {float: right;}
				&.aligncenter {float: none; margin: 20px auto; display: block;}
			}
			hr {height: 1px; border: none; background-color: #DEDEDE;}
			blockquote {font-style: italic; margin: 20px; font-size: 0.9em;}

			@include breakpoint(tabletwide) {
				width: 100%;
			} 

		} // > section
		&.archive, &.blog {
			div.post {
				border-bottom: 1px solid rgba(0,0,0,0.1);

				h3 {
					margin-bottom: 0;
				}

			}
		} // &.archive, &.blog

		@include breakpoint(tabletwide) {
			flex-direction: column;
		} 
	} // article.page


	article.home {
		width: 100%;
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		section {
			width: 1136px;

			@include breakpoint(tabletwide) {
				width: 100%;
			}

			&.hero {
				width: 100%;
				height: 80vh;
				// background : {
				// 	image: url('./images/backgrounds/hero-01.jpg');
				// 	position: center 25%;
				// 	color: transparent;
				// 	size: cover;
				// }
				background : {
					image: url('./images/backgrounds/hero-pedals.jpg');
					position: center 2rem;
					color: transparent;
					size: cover;
				}
				box-shadow: 0px 10px 30px -10px rgba(0,0,0,0.4);
				z-index: 1;
				color: #FFF;
				h1, h4 {
					font-weight: 300;
					text-align: center;
					display: block;
					text-shadow: 0px 2px 2px rgba(0,0,0,0.7);
				}
				h1 {
					font-size: 3rem;
					// margin: 15% auto 2rem;
					margin: 2rem auto 1rem;
					line-height: 0.9em;
				}
				h4 {
					font-size: 1.5rem;
					margin: 1rem auto 1rem;
					color: #9F9F9F;
				}

				@include breakpoint(mobilewide) {
					// height: 400px;
					background-position: center 5rem; 
					h1 {
						font-size: 2rem;
					}
				}
			}

			&.midballs {
				width: 100%;
				// height: 90vh;
				background : {
					// image: url('./images/backgrounds/midballs-tubes.png');
					position: center -60%;
					color: #303030;
					repeat: no-repeat;
					size: 100% auto;
				}

				> h2 {
					width: 1136px;
					font-weight: 300;
					color: #FFF;
					text-align: center;
					font-size: 2rem;
					margin: 1.5em auto 1em;

					@include breakpoint(tabletwide) {
						width: 100%;
					}

					em {
						font-style: normal;
						text-decoration: line-through;
						color: #5d5d5d;
					} // em
				} // > h2
			} // &.midballs

			&.lowballs {
				width: 100%;

				> h2 {
					width: 1136px;
					font-weight: 300;
					color: #FFF;
					text-align: center;
					font-size: 2rem;
					margin: 1.5em auto 1em;

					em {
						font-style: normal;
						text-decoration: line-through;
						color: #5d5d5d;
					} // em
				} // > h2


			} // &.lowballs
		}

		ul.partners {
			list-style: none;
			padding: 0;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: center;
			width: 1136px;

			li {
				margin: 0.5rem;

				a {
					display: block;
					background: #333;
					border-radius: 50%;
					width: 100px;
					height: 100px;
				}
			}
		}

		ul.homeWidget {
			list-style: none;
			padding: 0;
			margin: 0 auto 4rem;
			display: flex;
			flex-direction: row;
			//align-items: center;
			justify-content: center;
			width: 1136px;

			@include breakpoint(tabletwide) {
				width: 100%;
			}

			li {
				width: 30%;
				box-sizing: border-box;
				display: block;
				margin: 1rem;
				box-shadow: 0px 10px 30px -10px rgba(0,0,0,0.2);
				border-radius: .5rem;
				background-color: #FFF;
				box-shadow: 0px 10px 13px -10px rgba(0,0,0,0.7);
				transition: all 400ms ease-out;

				a {
					color: #063d62;
					font-weight: 300;
					line-height: normal;
					text-decoration: none;
					display: block;
					position: relative;
					padding: 15rem 0 2rem;
					text-align: center;
					background: {
						repeat: no-repeat;
						color: #333;
						size: 110% auto;
						position: center center;
					}
					border-radius: 5px;
					overflow: hidden;	
					transition: all 400ms ease-out;

					h2 {
						font-size: 1.5rem;
						font-weight: 300;
						margin: 1rem auto 0;
						padding: 0.5rem;
						position: absolute;
						bottom: 0; left: 0; right: 0;
						color: #FFF;
						transition: all 400ms ease-out;
						background: {
							color: transparentize($dfxdark, 0.1);
						}

						span {
							display: block;
							font-size: 0.7em;
							line-height: 0.7em;
						}
					}
					p {
						font-size: 1rem;
						font-weight: 300;
						padding: 1rem;
					}
					em {
						font-size: 1.2rem;
						font-weight: 300;
						display: none;
					}
				} // a

				&.products a {background-image: url('images/categories/cat-products.jpg');}
				&.repair a {background-image: url('images/categories/cat-repair.jpg');}
				&.custom a {background-image: url('images/categories/cat-custom.jpg');}

				&:hover {
					width: 33%;
					box-shadow: 0px 10px 30px -10px rgba(0,0,0,0.4);
					a {
						font-size: 2.8rem;
						background-size: 130% auto;

						h2 {
							font-size: 1.8rem;
							padding: 0.6rem;
						}

						em {
							display: block;
						}
					}
				} // &:hover

				// LI
				@include breakpoint(tabletwide) {
					width: 90%;
					margin: 1rem 5%;
					&:hover {
						width: 90%;
					}
				} 
			} // li
			@include breakpoint(tabletwide) {
				padding: 0;
				display: block;
			}
		} // ul.homeWidget

	} //article.home
	@include breakpoint(tabletwide) {
		width: 100%;
	} 
} // main
