/* Global Classes
////////////////////////////// */

a {
	color: #56a2ce;
	text-decoration: none;

	&:hover {
		color: #E9AF64;
		text-decoration: underline;	
	}
} // a