footer {
	padding: $spacing * 2;
	color: #ABB0AA;
	display: flex;
	justify-content: space-around;

	.contactinfo {
		// width: 30%;
		span {
			display: block;
			font-weight: 300;
		}
	}

	.social {
		a {
			color: #ABB0AA;
			
			i.fa {
				font-size: 2rem;
			}	
		}
		
	}
}