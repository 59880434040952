@media print {

	@page { margin: 0.5in 0.5in 1in 0.5in; }

	* {
		color: black !important;
		background: transparent !important;
		border-color: gray !important;
		border-width: 1pt !important;
		box-shadow: none !important;
		text-shadow: none !important;
		filter: none !important;
		-ms-filter: none !important;
	}

	html {
		font-size: 12pt;
	}

	a, a:visited { text-decoration: underline; }


}