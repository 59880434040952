aside {
	background-color: rgba(0,0,0,0.2);
	overflow: hidden;
	display: block;
	box-sizing: border-box;
	padding: 1rem;
	width: 30%;
	border-left: 1px solid rgba(0,0,0,0.1);

	h2 {
		font-size: 1.2em;
		font-weight: 300;
		margin-top: 0;
		text-transform: uppercase;
		color: #FFF;
		display: block;
	}

	a {
		color: #AFAFAF;

		&:hover {
			color: #D99F54;
		}
	}
	
	img {
		max-width: 100%;
		height: auto;
		&.alignleft {float: left; padding: 0 14px 0 0;}
		&.alignright {float: right; padding: 0 0 0 14px;}
		&.aligncenter {float: none; margin: 0 auto; display: block;}
	}

	.widget {

		// border-bottom: 1px solid #343434;
		padding: 1rem 0;

		&:first-of-type {
			padding: 0 0 1.4rem;
		}

		ul {
			&.product_list_widget {
				li {
					ins {
						color: #FFFFFF;
					}
					del {
						color: #8D8D8D;
					}
				}
			}
			&.product-categories {
				list-style: none;
				margin: 0;
				padding: 0;

				li {

				}
			}
		}

		&.center {
			text-align: center;
		}

		&.socialmedia {
			display: flex;
			flex-direction: row;
			//align-items: center;
			//justify-content: center;
			a {
				display: block;
				margin: 0 .5rem;
				i {font-size: 2.4rem;}
			}
		}
	} // . widget

	.widget_nav_menu {
		
		ul.menu {
			padding: 0;
			margin: 0;
			list-style: none;

			li {

				a {
					display: block;
					font-weight: 300;
					text-transform: uppercase;
					padding: 0.3em 1em;
					border-radius: 5px;
					box-shadow: inset 0px -10px 10px -10px rgba(0,0,0,0);
					transition: box-shadow 400ms ease-out;

					&:hover {
						background-color: #2288C2;
						text-decoration: none;
						color: #FFF;
						box-shadow: inset 0px -10px 10px -10px rgba(0,0,0,0.3);
						text-shadow: 0 1px 1px rgba(0,0,0,0.7);
					}
				}
			}
		} // ul.menu
	} // .widget_nav_menu

	@include breakpoint(tabletwide) {
		width: 100%;
	} 
}