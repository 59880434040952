/* ## Sizes ## */
$margin: 1rem;
$spacing: 1rem;
$radius: 3px;

// Transitions
$transition: all .4s;
$transition-delay: all .4s .5s;

// PHOTOSHOP FILTERS (lol)
$shadow: 0 1px 4px rgba(0,0,0,1);
$shadowLight: 0 1px 4px rgba(0,0,0,0.4);


/* ## Fonts ## */
$sans-serif: 'Open Sans', Helvetica, Arial, Helmet, Freesans, sans-serif;
$serif: Georgia, 'Times New Roman', Times, serif;
$monospace: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;

$font-url--google: 'https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,300,400,600';

// Font Weights
$font-weight--light: 300;
$font-weight--normal: 400;
$font-weight--bold:	600;

// DFX
$dfxdark: #191919;
$dfxmiddark: #303030;

// MISC
$orange: #c97e1b;
$red: #a21c0d;
$blue: #416E92;
$green: #4e792f;
$purple: #702e6e;
// General
$red: #c41200;
$yellow: #f4bd27;
$blue: #094ab2;
$green: #107c10;
$orange: #f90;
$purple: #409;
$gray: #999;

// Social
$facebook: #3b5998;
$googlePlus: #dc4e41;
$instagram: #125688;
$linkedIn: #0077b5;
$pinterest: #bd081c;
$rss: #ffa500;
$twitter: #55aace;
$youtube: #cd201f;

/*for table row highlighting*/
$highlight: #F2FFC5;

$light: #e6e6e6;
$medium: #ccc;
$dark: #333;


/* ## Media Query Break point sizes ## */
$break-large: 1024px;
$break-medium: 768px;
$break-small: 480px;
$break-grid: 640px;
